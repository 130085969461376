import { useState } from "react";

import {
  Card,
  Col,
  Row,
  Progress,
  Table,
  Avatar,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import ava1 from "../assets/images/logo-shopify.svg";
import Swap from "../assets/images/swap.svg";
import List from "../assets/images/list.svg";
import User from "../assets/images/user.svg";
import pencil from "../assets/images/pencil.svg";
import Microphone from "../assets/images/microphone.svg";
import AddUser from "../assets/images/add-user.svg";
import Refresh from "../assets/images/refresh.svg";

const { Title } = Typography;
const project = [
  {
    title: "Sr .No.",
    dataIndex: "srn",
  },
  {
    title: "Account",
    dataIndex: "account",
  },
  {
    title: "Credit Ref.",
    dataIndex: "working",
  },
  {
    title: "Balance",
    dataIndex: "balance",
  },
  {
    title: "Exposure",
    dataIndex: "exposure",
  },
  {
    title: "Avail. bal.",
    dataIndex: "availbal",
  },
  {
    title: "Exposure Limit",
    dataIndex: "exposurelimit",
  },
  {
    title: "Commission's",
    dataIndex: "commission",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];
const dataproject = [
  {
    key: "1",

    srn: (
      <>
        <Avatar.Group>
          <Avatar className="shape-avatar" src={ava1} size={25} alt="" />
          <div className="avatar-info">
            <Title level={5}>Spotify Version</Title>
          </div>
        </Avatar.Group>
      </>
    ),
    account: (
      <>
        <div className="semibold">
        <a href="#" className="text-primary"><span>CL</span></a>
        9799423081
        </div>
      </>
    ),
    working: (
      <>
       <div className="ant-progress-project">
         0.00
          <span>
            <Link to="/">
              <img src={pencil} alt="" />
            </Link>
          </span>
        </div>
      </>
    ),
    balance: (
      <>
        <div className="text-primary">0.00</div>
      </>
    ),
    exposure: (
      <>
        <a href="#"><span className="status-suspend1">0.00</span></a>
      </>
    ),
    availbal: (
      <>
       <div className="avail-bal">0.00</div>
      </>
    ),
    exposurelimit: (
      <>
       <div className="avail-bal">1,000,000.00</div>
      </>
    ),
    commission: (
      <>
       <div className="avail-bal">0.00</div>
      </>
    ),
    status: (
      <>
       <strong className="status-active">Active</strong>
      </>
    ),
    action: (
      <>
      <div className="action_link">
        <a className="btn" href="#">
          <img src={Swap} />
        </a>
        <a title="Betting History" className="btn" href="#">
          <img src={List} />
        </a>
        <a title="Account Summary" className="btn" href="#">
          <img src={User} />
        </a>
      </div>
      </>
    ),
  },
];

function Betlist() {

  return (
    <>
      <div className="layout-content">
      <h2 class="common-heading">Bet List</h2>
        <form class="">
        <div class="d-lg-flex">
            <div class="mb-1">
                
                <ul class="list-unstyled mb-0 d-flex">
                    <li>
                        <div class="form-check form-check-inline">
                            <input name="4" type="radio" id="inline-radio-1" class="form-check-input" checked="" />
                            <label title="" for="inline-radio-1" class="form-check-label">Cricket</label>
                        </div>
                    </li>
                    <li>
                        <div class="form-check form-check-inline">
                            <input name="2" type="radio" id="inline-radio-1" class="form-check-input" />
                            <label title="" for="inline-radio-1" class="form-check-label">Tennis</label>
                        </div>
                    </li>
                    <li>
                        <div class="form-check form-check-inline">
                            <input name="1" type="radio" id="inline-radio-1" class="form-check-input" />
                            <label title="" for="inline-radio-1" class="form-check-label">Soccer</label>
                        </div>
                    </li>
                    <li>
                        <div class="form-check form-check-inline">
                            <input name="3" type="radio" id="inline-radio-1" class="form-check-input" />
                            <label title="" for="inline-radio-1" class="form-check-label">Casino</label>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="mb-1">
                <ul class="list-unstyled mb-0 d-flex">
                    <li>
                        <div class="form-check form-check-inline">
                            <input name="betfair" type="radio" id="inline-radio-1" class="form-check-input" checked="" />
                            <label title="" for="inline-radio-1" class="form-check-label">Bet Fair</label>
                        </div>
                    </li>
                    <li>
                        <div class="form-check form-check-inline">
                            <input name="bookmaker" type="radio" id="inline-radio-1" class="form-check-input" />
                            <label title="" for="inline-radio-1" class="form-check-label">Bookmaker</label>
                        </div>
                    </li>
                    <li>
                        <div class="form-check form-check-inline">
                            <input name="fancy" type="radio" id="inline-radio-1" class="form-check-input" />
                            <label title="" for="inline-radio-1" class="form-check-label">Fancy</label>
                        </div>
                    </li>
                    <li>
                        <div class="form-check form-check-inline">
                            <input name="sportBook" type="radio" id="inline-radio-1" class="form-check-input" />
                            <label title="" for="inline-radio-1" class="form-check-label">SportsBook</label>
                        </div>
                    </li>
                    <li>
                        <div class="form-check form-check-inline">
                            <input name="tie" type="radio" id="inline-radio-1" class="form-check-input" />
                            <label title="" for="inline-radio-1" class="form-check-label">Tie</label>
                        </div>
                    </li>
                    <li>
                        <div class="form-check form-check-inline">
                            <input name="toss" type="radio" id="inline-radio-1" class="form-check-input" />
                            <label title="" for="inline-radio-1" class="form-check-label">Toss</label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="bet_status mb-0">
            <Row gutter={24}>
                <Col md={12} lg={6}>
                    <div class="bet-sec">
                        <label class="mt-2 me-2 form-label">Bet Status:</label>
                        <select aria-label="Default select example" class="small_select form-select" fdprocessedid="wq9n6">
                            <option value="unmatched">Unmatched</option>
                            <option value="matched">Matched</option>
                            <option value="completed">Settled</option>
                            <option value="suspend">Cancelled</option>
                            <option value="voided">Voided</option>
                        </select>
                    </div>
                </Col>
                <Col md={12} lg={6}>
                    <div class="bet-sec">
                        <label class="form-label">Last</label>
                        <select aria-label="Default select example" class="small_select form-select" fdprocessedid="0cufzd">
                            <option value="100">100 Txn</option>
                            <option value="200">200 Txn</option>
                            <option value="500">500 Txn</option>
                            <option value="1000">1000 Txn</option>
                            <option value="5000">5000 Txn</option>
                            <option value="10000">10000 Txn</option>
                            <option value="">All</option>
                        </select>
                    </div>
                </Col>
                <Col md={12} lg={6}>
                    <div class="bet-sec bet-period">
                        <label class="mt-2 me-2 form-label">From</label>
                        <div class="form-group">
                            <input max="2024-07-10" type="date" class="form-control" value="2024-07-09" />
                            
                        </div>
                    </div>
                </Col>
                <Col md={12} lg={6}>
                    <div class="bet-sec bet-period">
                        <label class="mt-2 me-2 form-label">To</label>
                        <div class="form-group">
                            <input min="2024-07-09" max="2024-07-10" type="date" class="form-control" value="2024-07-10" />
                           
                        </div>
                    </div>
                </Col>
            </Row>
            <div class="history-btn mt-3">
                <ul class="list-unstyled mb-0">
                    <li>
                        <button type="button" class="me-0 theme_light_btn btn btn-primary" fdprocessedid="7acob8">Just For Today</button>
                    </li>
                    <li>
                        <button type="button" class="me-0 theme_light_btn btn btn-primary" fdprocessedid="6l8mk5">From Yesterday</button>
                    </li>
                    <li>
                        <button type="button" class="theme_light_btn theme_dark_btn btn btn-primary" fdprocessedid="r4qs2a">Search</button>
                    </li>
                    <li>
                        <button type="button" class="theme_light_btn btn btn-primary" fdprocessedid="n32kw">Reset</button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="batting-content"><p>Betting History enables you to review the bets you have placed. Specify the time period during which your bets were placed, the type of markets on which the bets were placed, and the sport.<br />Betting History is available online for the past 30 days.</p></div>
        </form>
        <Row>
          <Col md={24}>
            <div className="table-responsive mt-3">
              <Table
                columns={project}
                dataSource={dataproject}
                pagination={true}
                className="ant-border-space"
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Betlist;

import { useState } from "react";

import {
  Card,
  Col,
  Row,
  Progress,
  Table,
  Avatar,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import ava1 from "../assets/images/logo-shopify.svg";
import Swap from "../assets/images/swap.svg";
import List from "../assets/images/list.svg";
import User from "../assets/images/user.svg";
import ava6 from "../assets/images/logo-invision.svg";
import pencil from "../assets/images/pencil.svg";
import Microphone from "../assets/images/microphone.svg";
import AddUser from "../assets/images/add-user.svg";
import Refresh from "../assets/images/refresh.svg";
import Echart from "../components/chart/EChart";
import LineChart from "../components/chart/LineChart";
const { Title } = Typography;
const project = [
  {
    title: "Sr .No.",
    dataIndex: "srn",
  },
  {
    title: "Account",
    dataIndex: "account",
  },
  {
    title: "Credit Ref.",
    dataIndex: "working",
  },
  {
    title: "Balance",
    dataIndex: "balance",
  },
  {
    title: "Exposure",
    dataIndex: "exposure",
  },
  {
    title: "Avail. bal.",
    dataIndex: "availbal",
  },
  {
    title: "Exposure Limit",
    dataIndex: "exposurelimit",
  },
  {
    title: "Commission's",
    dataIndex: "commission",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];
const dataproject = [
  {
    key: "1",

    srn: (
      <>
        <Avatar.Group>
          <Avatar className="shape-avatar" src={ava1} size={25} alt="" />
          <div className="avatar-info">
            <Title level={5}>Spotify Version</Title>
          </div>
        </Avatar.Group>
      </>
    ),
    account: (
      <>
        <div className="semibold">
        <a href="#" className="text-primary"><span>CL</span></a>
        9799423081
        </div>
      </>
    ),
    working: (
      <>
       <div className="ant-progress-project">
         0.00
          <span>
            <Link to="/">
              <img src={pencil} alt="" />
            </Link>
          </span>
        </div>
      </>
    ),
    balance: (
      <>
        <div className="text-primary">0.00</div>
      </>
    ),
    exposure: (
      <>
        <a href="#"><span className="status-suspend1">0.00</span></a>
      </>
    ),
    availbal: (
      <>
       <div className="avail-bal">0.00</div>
      </>
    ),
    exposurelimit: (
      <>
       <div className="avail-bal">1,000,000.00</div>
      </>
    ),
    commission: (
      <>
       <div className="avail-bal">0.00</div>
      </>
    ),
    status: (
      <>
       <strong className="status-active">Active</strong>
      </>
    ),
    action: (
      <>
      <div className="action_link">
        <a className="btn" href="#">
          <img src={Swap} />
        </a>
        <a title="Betting History" className="btn" href="#">
          <img src={List} />
        </a>
        <a title="Account Summary" className="btn" href="#">
          <img src={User} />
        </a>
      </div>
      </>
    ),
  },
];

function Downline() {

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <section className="breadcum-sec">
              <div className="container-fluid">
                <div className="bredcum-sec-main">
                  <span>
                    <img src={Microphone} />
                  </span>
                  <h5 className="mb-0">News</h5>
                </div>
              </div>
            </section>
          </Col>
        </Row>
        <section className="find-member-sec py-3">
          <div className="container-fluid">
            <Row>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <form id="searchForm">
                  <div className="position-relative">
                    <input placeholder="Find member..." type="text" className="form-control" fdprocessedid="abqo5m" />
                    <i className="fas fa-search"></i>
                    <button type="button" className="search-btn btn btn-primary" fdprocessedid="k8vwu">Search</button>
                  </div>
                  <div className="d-flex align-items-center ps-2">
                    <label className="pe-3 mb-0 form-label">Status</label>
                    <select aria-label="Default select example" className="form-select" fdprocessedid="82pdyf">
                      <option value="active">Active</option>
                      <option value="suspend">Suspend</option>
                      <option value="locked">Locked</option>
                      <option value="">All</option>
                    </select>
                  </div>
                </form>
              </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <div className="d-flex flex-wrap justify-content-between">
                    <div className="find-member-director">
                      <a href="#" className="btn">
                        <img src={AddUser} />
                          Add User</a>
                      <a href="#" className="btn">
                        <img src={Refresh} alt="" />
                      </a>
                    </div>
                  </div>
                </Col>
            </Row>
          </div>
        </section>
        <section className="total-balance-sec">
          <div className="container-fluid">
              <ul className="list-unstyled">
                  <li><dt>Total Balance</dt><strong>BDT 0.00</strong></li>
                  <li><dt>Total Exposure</dt><strong>BDT <span>0.00</span></strong></li>
                  <li><dt>Total Avail. bal.</dt><strong>BDT 0.00</strong></li>
                  <li><dt>Balance</dt><strong>BDT 0.00</strong></li>
                  <li><dt>Available Balance</dt><strong>BDT 0.00</strong></li>
                  <li><dt>Total Player Balance</dt><strong>BDT 0.00</strong></li>
              </ul>
          </div>
      </section>
        <Row>
          <Col md={24}>
            <h2 className="common-heading">New Registered Member ( Last 24 Hours )</h2>
            <div className="table-responsive mt-3">
              <Table
                columns={project}
                dataSource={dataproject}
                pagination={false}
                className="ant-border-space"
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Downline;


import { useState } from "react";

import {
  Row,
  Col,
  List,
  Table,
  Avatar,
  Radio,
  Switch,
  Upload,

  Typography,
  Tabs,
  message,
} from "antd";
import { Link } from "react-router-dom";
import ava1 from "../assets/images/logo-shopify.svg";
import Swap from "../assets/images/swap.svg";
import User from "../assets/images/user.svg";
import pencil from "../assets/images/pencil.svg";

const { TabPane } = Tabs;
const { Title } = Typography;
const project = [
  {
    title: "Sr .No.",
    dataIndex: "srn",
  },
  {
    title: "Account",
    dataIndex: "account",
  },
  {
    title: "Credit Ref.",
    dataIndex: "working",
  },
  {
    title: "Balance",
    dataIndex: "balance",
  },
  {
    title: "Exposure",
    dataIndex: "exposure",
  },
  {
    title: "Avail. bal.",
    dataIndex: "availbal",
  },
  {
    title: "Exposure Limit",
    dataIndex: "exposurelimit",
  },
  {
    title: "Commission's",
    dataIndex: "commission",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];
const dataproject = [
  {
    key: "1",

    srn: (
      <>
        <Avatar.Group>
          <Avatar className="shape-avatar" src={ava1} size={25} alt="" />
          <div className="avatar-info">
            <Title level={5}>Spotify Version</Title>
          </div>
        </Avatar.Group>
      </>
    ),
    account: (
      <>
        <div className="semibold">
        <a href="#" className="text-primary"><span>CL</span></a>
        9799423081
        </div>
      </>
    ),
    working: (
      <>
       <div className="ant-progress-project">
         0.00
          <span>
            <Link to="/">
              <img src={pencil} alt="" />
            </Link>
          </span>
        </div>
      </>
    ),
    balance: (
      <>
        <div className="text-primary">0.00</div>
      </>
    ),
    exposure: (
      <>
        <a href="#"><span className="status-suspend1">0.00</span></a>
      </>
    ),
    availbal: (
      <>
       <div className="avail-bal">0.00</div>
      </>
    ),
    exposurelimit: (
      <>
       <div className="avail-bal">1,000,000.00</div>
      </>
    ),
    commission: (
      <>
       <div className="avail-bal">0.00</div>
      </>
    ),
    status: (
      <>
       <strong className="status-active">Active</strong>
      </>
    ),
    action: (
      <>
      <div className="action_link">
        <a className="btn" href="#">
          <img src={Swap} />
        </a>
        <a title="Betting History" className="btn" href="#">
          <img src={List} />
        </a>
        <a title="Account Summary" className="btn" href="#">
          <img src={User} />
        </a>
      </div>
      </>
    ),
  },
];

function Profile() {
  const [imageURL, setImageURL] = useState(false);
  const [, setLoading] = useState(false);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };


  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(false);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false);
        setImageURL(false);
      });
    }
  };


  return (
    <>
    <div className="agent-path mb-4">
      <ul className="m-0 list-unstyled">
        <li>
          <a href="#">
            <span>AG</span>
          <strong>vkj01</strong>
          </a>
          </li>
    </ul>
    </div>
    
    <Tabs
        defaultActiveKey="1"
        tabPosition="left"
        // onChange={callback}
        tabBarStyle={{ color: "#8D949C" }}
        style={{ width: "100%" }}
      >
    <TabPane
      tab="Account Statement"
      key="1"
      // style={{ backgroundColor: "red", color: "#000" }}
    >
        <h2 className="common-heading">Account Statement</h2>
        <Row>
          <Col md={24}>
            <div className="table-responsive mt-3">
              <Table
                columns={project}
                dataSource={dataproject}
                pagination={true}
                className="ant-border-space"
              />
            </div>
          </Col>
        </Row>
    </TabPane>
    <TabPane tab="Account Summary" key="2">
      <h2 className="common-heading">Account Summary</h2>
      <div className="bg-white py-2 px-3 total-balance-summary">
        <div className="first_cols">
          <dt>Total Balance</dt>
          <strong>0.00 <sub>BDT</sub></strong>
        </div>
      </div>
    </TabPane>
    <TabPane tab="Profile" key="3">
      <h2 className="common-heading">profile</h2>
    </TabPane>
    <TabPane tab="Activity Log" key="4">

      <h2 className="common-heading">Activity Log</h2>
      <Row>
          <Col md={24}>
            <div className="table-responsive mt-3">
              <Table
                columns={project}
                dataSource={dataproject}
                pagination={false}
                className="ant-border-space"
              />
            </div>
          </Col>
        </Row>
    </TabPane>
  </Tabs>
    </>
  );
}

export default Profile;

import { useState } from "react";

import {
  Card,
  Col,
  Row,
  Progress,
  Table,
  Avatar,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import ava1 from "../assets/images/logo-shopify.svg";
import Swap from "../assets/images/swap.svg";
import List from "../assets/images/list.svg";
import User from "../assets/images/user.svg";
import pencil from "../assets/images/pencil.svg";


const { Title } = Typography;
const project = [
  {
    title: "Sr .No.",
    dataIndex: "srn",
  },
  {
    title: "Account",
    dataIndex: "account",
  },
  {
    title: "Credit Ref.",
    dataIndex: "working",
  },
  {
    title: "Balance",
    dataIndex: "balance",
  },
  {
    title: "Exposure",
    dataIndex: "exposure",
  },
  {
    title: "Avail. bal.",
    dataIndex: "availbal",
  },
  {
    title: "Exposure Limit",
    dataIndex: "exposurelimit",
  },
  {
    title: "Commission's",
    dataIndex: "commission",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];
const dataproject = [
  {
    key: "1",

    srn: (
      <>
        <Avatar.Group>
          <Avatar className="shape-avatar" src={ava1} size={25} alt="" />
          <div className="avatar-info">
            <Title level={5}>Spotify Version</Title>
          </div>
        </Avatar.Group>
      </>
    ),
    account: (
      <>
        <div className="semibold">
        <a href="#" className="text-primary"><span>CL</span></a>
        9799423081
        </div>
      </>
    ),
    working: (
      <>
       <div className="ant-progress-project">
         0.00
          <span>
            <Link to="/">
              <img src={pencil} alt="" />
            </Link>
          </span>
        </div>
      </>
    ),
    balance: (
      <>
        <div className="text-primary">0.00</div>
      </>
    ),
    exposure: (
      <>
        <a href="#"><span className="status-suspend1">0.00</span></a>
      </>
    ),
    availbal: (
      <>
       <div className="avail-bal">0.00</div>
      </>
    ),
    exposurelimit: (
      <>
       <div className="avail-bal">1,000,000.00</div>
      </>
    ),
    commission: (
      <>
       <div className="avail-bal">0.00</div>
      </>
    ),
    status: (
      <>
       <strong className="status-active">Active</strong>
      </>
    ),
    action: (
      <>
      <div className="action_link">
        <a className="btn" href="#">
          <img src={Swap} />
        </a>
        <a title="Betting History" className="btn" href="#">
          <img src={List} />
        </a>
        <a title="Account Summary" className="btn" href="#">
          <img src={User} />
        </a>
      </div>
      </>
    ),
  },
];

function RiskManagement() {

  return (
    <>
      <div className="layout-content">
        <h2 class="common-heading">Risk Management</h2>
        <Row>
          <Col md={24}>
            <div className="table-responsive mt-3">
              <Table
                columns={project}
                dataSource={dataproject}
                pagination={true}
                className="ant-border-space"
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default RiskManagement;

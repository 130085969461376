import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import Logout from "../../assets/images/logout.svg";

function Sidenav({ color }) {
  const { pathname } = useLocation();
  

  return (
    <>
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/downline">
            <span className="label">Downline List</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/profile">
            <span className="label">My Account</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="/betlist">
           
            <span className="label">BetList</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="5">
          <NavLink to="/betlistlive">
            <span className="label">BetListLive</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/risk-management">
            <span className="label">Risk Management</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="7">
          <NavLink to="/commission">
            <span className="label">Commission</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="8">
          <NavLink to="/admin-setting">
            <span className="label">Admin Setting</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="9">
            <span className="timezone">Time Zone : GMT+5:30</span>
        </Menu.Item>
        <Menu.Item key="10">
          <NavLink to="/Signin">
            <span className="label">Logout </span>
            <span className="logout-ic">
              <img src={Logout} />
            </span>
          </NavLink>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default Sidenav;

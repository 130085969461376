
import { useState } from "react";

import {
  Card,
  Col,
  Row,
  Progress,
  Table,
  Avatar,
  Button,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import ava1 from "../assets/images/logo-shopify.svg";
import pencil from "../assets/images/pencil.svg";
import Swap from "../assets/images/swap.svg";
import List from "../assets/images/list.svg";
import User from "../assets/images/user.svg";
const { Title } = Typography;

const project = [
  {
    title: "SR",
    dataIndex: "sr",
  },
  {
    title: "Account Name",
    dataIndex: "accountname",
  },
  {
    title: "Bank Name",
    dataIndex: "bankname",
  },
  {
    title: "Bank Account",
    dataIndex: "bankaccount",
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  {
    title: "Created Date",
    dataIndex: "createddate",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Remark",
    dataIndex: "remark",
  },
  {
    title: "",
    dataIndex: "nodata",
  },
];
// table code start
const columns = [
  {
    title: "SR",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "DATE",
    dataIndex: "function",
    key: "function",
  },

  {
    title: "COMMISSION",
    key: "commission",
    dataIndex: "commission",
  },
];

const data = [
  {
    key: "1",
    name: (
      <>
        <div className="sr-no">
          1
        </div>
      </>
    ),
    function: (
      <>
        <div className="table-info">
          <p>2024-07-03</p>
        </div>
      </>
    ),

    commission: (
      <>
        <p>0.00</p>
      </>
    ),
  },

  {
    key: "2",
    name: (
      <>
        <div className="sr-no">
          2
        </div>
      </>
    ),
    function: (
      <>
        <div className="table-info">
          <p>2024-07-03</p>
        </div>
      </>
    ),

    commission: (
      <>
        <p>0.00</p>
      </>
    ),
  },

  {
    key: "3",
    name: (
      <>
        <div className="sr-no">
          3
        </div>
      </>
    ),
    function: (
      <>
        <div className="table-info">
          <p>2024-07-03</p>
        </div>
      </>
    ),

    commission: (
      <>
        <p>0.00</p>
      </>
    ),
  },
];

function Commission() {

  return (
    <>
      <div className="layout-content">
        <div className="marquee">
            <div className='marquee-inner'>
              <div className="marquee-content">সপ্তাহে ৬০% কমিশন ব্যাংকের মাধ্যমে পুরোটাই উইথড্র করতে পারবেন। বিকাশ/নাগাদে দিনে ৩০ করে সপ্তাহে ২ লাখ উত্তলন করতে পারেন। ধন্যবাদ</div>
            </div>
        </div>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} className="mb-24">
              <h5 className="p-2 title_wrap">Commission Report</h5>
              <section className="total-balance-sec tbc">
                <div className="px-0 container-fluid">
                    <ul className="list-unstyled">
                        <li><dt>Total Turnour</dt><strong>BDT 0.00</strong></li>
                        <li><dt>Total Commission</dt><strong>BDT 0.00</strong></li>
                    </ul>
                </div>
            </section>
              <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    className="ant-border-space"
                  />
                </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
              <h5 className="p-2 title_wrap">Settlement History</h5>
              <section className="total-balance-sec was">
                <div className="px-0 container-fluid">
                    <ul className="list-unstyled">
                        <li><dt>Withdrawal Amount</dt><strong>BDT 0.00</strong></li>
                        <li>
                            <button className="withdraw-btn">Withdraw Request</button>
                        </li>
                    </ul>
                </div>
            </section>
            <div className="table-responsive mt-3">
              <Table
                columns={project}
                pagination={false}
                className="ant-border-space"
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Commission;

import { useState } from "react";

import {
  Card,
  Col,
  Row,
  Progress,
  Table,
  Avatar,
  Modal,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import ChangePw from "../assets/images/changepass.png";


const { Title } = Typography;

function AdminSetting() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className="layout-content">
      <h2 className="common-heading">Admin Setting</h2>
      <div className="setting_dashboard">
        <div className="setting_dashboard_block">
          <h2 className="common-heading">General Settings</h2>
          <ul>
            <li>
            <a href="#" onClick={showModal}>
              <figure>
                <img src={ChangePw} alt="Change Password" /> 
              </figure>
            </a>
            </li>
          </ul>
        </div>
        </div>
      <Modal title="Change Password" className="changepw-modal" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <form className="change-password-sec">
          <div className="d-flex  mb-2">
              <label className="form-label">Old Password</label>
              <input placeholder="Enter Old Password" name="oldPassword" type="password" className="form-control" />
          </div>
          <div className="d-flex  mb-2">
              <label className="form-label">New Password</label>
              <input placeholder="Enter New Password" name="newPassword" type="password" className="form-control" />
          </div>
          <div className="d-flex  mb-2">
              <label className="form-label">New Password Confirm</label>
              <input placeholder="Confirm Password" name="confirmPassword" type="password" className="form-control" />
          </div>
          <div className="text-center mt-4">
              <button type="submit" className="green-btn btn btn-primary" fdprocessedid="sn1dh">Change</button>
          </div>
      </form>
      </Modal>
      </div>
    </>
  );
}

export default AdminSetting;

import { useState } from "react";

import {
  Card,
  Col,
  Row,
  Progress,
  Table,
  Avatar,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import ava1 from "../assets/images/logo-shopify.svg";
import Swap from "../assets/images/swap.svg";
import List from "../assets/images/list.svg";
import User from "../assets/images/user.svg";
import pencil from "../assets/images/pencil.svg";

const { Title } = Typography;
const project = [
  {
    title: "Sr .No.",
    dataIndex: "srn",
  },
  {
    title: "Account",
    dataIndex: "account",
  },
  {
    title: "Credit Ref.",
    dataIndex: "working",
  },
  {
    title: "Balance",
    dataIndex: "balance",
  },
  {
    title: "Exposure",
    dataIndex: "exposure",
  },
  {
    title: "Avail. bal.",
    dataIndex: "availbal",
  },
  {
    title: "Exposure Limit",
    dataIndex: "exposurelimit",
  },
  {
    title: "Commission's",
    dataIndex: "commission",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];
const dataproject = [
  {
    key: "1",

    srn: (
      <>
        <Avatar.Group>
          <Avatar className="shape-avatar" src={ava1} size={25} alt="" />
          <div className="avatar-info">
            <Title level={5}>Spotify Version</Title>
          </div>
        </Avatar.Group>
      </>
    ),
    account: (
      <>
        <div className="semibold">
        <a href="#" className="text-primary"><span>CL</span></a>
        9799423081
        </div>
      </>
    ),
    working: (
      <>
       <div className="ant-progress-project">
         0.00
          <span>
            <Link to="/">
              <img src={pencil} alt="" />
            </Link>
          </span>
        </div>
      </>
    ),
    balance: (
      <>
        <div className="text-primary">0.00</div>
      </>
    ),
    exposure: (
      <>
        <a href="#"><span className="status-suspend1">0.00</span></a>
      </>
    ),
    availbal: (
      <>
       <div className="avail-bal">0.00</div>
      </>
    ),
    exposurelimit: (
      <>
       <div className="avail-bal">1,000,000.00</div>
      </>
    ),
    commission: (
      <>
       <div className="avail-bal">0.00</div>
      </>
    ),
    status: (
      <>
       <strong className="status-active">Active</strong>
      </>
    ),
    action: (
      <>
      <div className="action_link">
        <a className="btn" href="#">
          <img src={Swap} />
        </a>
        <a title="Betting History" className="btn" href="#">
          <img src={List} />
        </a>
        <a title="Account Summary" className="btn" href="#">
          <img src={User} />
        </a>
      </div>
      </>
    ),
  },
];

function BetlistLive() {

  return (
    <>
      <div className="layout-content">
      <h2 className="common-heading">Bet List</h2>
      <form className="">
        <div className="d-lg-flex">
            <div className="mb-1">
                <ul className="list-unstyled mb-0 d-flex">
                    <li>
                        <div className="form-check form-check-inline">
                            <input name="4" type="radio" id="inline-radio-1" className="form-check-input" checked="" />
                            <label title="" for="inline-radio-1" className="form-check-label">Cricket</label>
                        </div>
                    </li>
                    <li>
                        <div className="form-check form-check-inline">
                            <input name="2" type="radio" id="inline-radio-1" className="form-check-input" />
                            <label title="" for="inline-radio-1" className="form-check-label">Tennis</label>
                        </div>
                    </li>
                    <li>
                        <div className="form-check form-check-inline">
                            <input name="1" type="radio" id="inline-radio-1" className="form-check-input" />
                            <label title="" for="inline-radio-1" className="form-check-label">Soccer</label>
                        </div>
                    </li>
                    <li>
                        <div className="form-check form-check-inline">
                            <input name="3" type="radio" id="inline-radio-1" className="form-check-input" />
                            <label title="" for="inline-radio-1" className="form-check-label">Casino</label>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="mb-1">
                <ul className="list-unstyled mb-0 d-flex">
                    <li>
                        <div className="form-check form-check-inline">
                            <input name="betfair" type="radio" id="inline-radio-1" className="form-check-input" checked="" />
                            <label title="" for="inline-radio-1" className="form-check-label">Bet Fair</label>
                        </div>
                    </li>
                    <li>
                        <div className="form-check form-check-inline">
                            <input name="bookmaker" type="radio" id="inline-radio-1" className="form-check-input" />
                            <label title="" for="inline-radio-1" className="form-check-label">Bookmaker</label>
                        </div>
                    </li>
                    <li>
                        <div className="form-check form-check-inline">
                            <input name="fancy" type="radio" id="inline-radio-1" className="form-check-input" />
                            <label title="" for="inline-radio-1" className="form-check-label">Fancy</label>
                        </div>
                    </li>
                    <li>
                        <div className="form-check form-check-inline">
                            <input name="sportBook" type="radio" id="inline-radio-1" className="form-check-input" />
                            <label title="" for="inline-radio-1" className="form-check-label">SportsBook</label>
                        </div>
                    </li>
                    <li>
                        <div className="form-check form-check-inline">
                            <input name="tie" type="radio" id="inline-radio-1" className="form-check-input" />
                            <label title="" for="inline-radio-1" className="form-check-label">Tie</label>
                        </div>
                    </li>
                    <li>
                        <div className="form-check form-check-inline">
                            <input name="toss" type="radio" id="inline-radio-1" className="form-check-input" />
                            <label title="" for="inline-radio-1" className="form-check-label">Toss</label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div className="bet_status mb-0">
          <div className="bet_outer">
            <div className="bet-sec">
                <label className="form-label">Order of display:</label>
                <select aria-label="Default select example" className="small_select form-select" fdprocessedid="tjp53j">
                    <option value="amount">Stake</option>
                    <option value="timeInserted">Time</option>
                </select>
            </div>
            <div className="bet-sec bet-period">
                <label className="form-label">of</label>
                <select aria-label="Default select example" className="small_select form-select" fdprocessedid="24vgz">
                    <option value="asc">Ascending</option>
                    <option value="desc">Decending</option>
                </select>
            </div>
            <div className="bet-sec bet-period">
                <label className="form-label">Last</label>
                <select aria-label="Default select example" className="small_select form-select" fdprocessedid="78gld">
                    <option value="25">25 Txn</option>
                    <option value="50">50 Txn</option>
                    <option value="100">100 Txn</option>
                    <option value="200">200 Txn</option>
                    <option value="">All</option>
                </select>
            </div>
            <div className="bet-sec bet-period">
                <label className="form-label">Auto Refresh (Seconds) </label>
                <select aria-label="Default select example" className="small_select form-select" fdprocessedid="ibven">
                    <option value="">Stop</option>
                    <option value="60">60</option>
                    <option value="30">30</option>
                    <option value="15">15</option>
                    <option value="5">5</option>
                    <option value="2">2</option>
                </select>
            </div>
            <div className="bet-sec">
                <label className="form-label">Bet Status:</label>
                <select aria-label="Default select example" className="small_select form-select" fdprocessedid="qw2w4">
                    <option value="active">Active</option>
                    <option value="suspend">Suspend</option>
                </select>
            </div>
            <div className="d-flex">
                <button type="button" className="theme_dark_btn btn btn-primary" fdprocessedid="e2g5lh">Search</button>
                <button type="button" className="theme_light_btn btn btn-primary" fdprocessedid="ec824r">Reset</button>
            </div>
        </div>
        </div>
        <div className="batting-content"><p>Betting History enables you to review the bets you have placed. Specify the time period during which your bets were placed, the type of markets on which the bets were placed, and the sport.<br />Betting History is available online for the past 30 days.</p></div>
    </form>
        <Row>
          <Col md={24}>
            <div className="table-responsive mt-3">
              <Table
                columns={project}
                dataSource={dataproject}
                pagination={false}
                className="ant-border-space"
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default BetlistLive;
